import axios from "axios";

import { accountService } from "./account.service";

const postAxios = (options)=>{

    console.log(window.location.host);

    let URL= window.routes.URLAPI;

    // switch(window.location.host){
    //     case 'www.soleilduloir.fr':
    //     case 'soleilduloir.fr':
    //         URL= 'https://www.admin.soleilduloir.fr/index.php'
    //         break;
    //     default :
    //         URL= window.routes.URLAPI ;
    // }

    const defaults = {
        values:{
            domaine: 'front',
            action: 'test',
            espace: 'public'
        },
        errorIdent: data =>{
            accountService.logOut();
            window.location.href='/AR/connectExpire';
            console.log(data);
        },
        errorData: data =>{
            console.log(data.message)
        },
        OKData: data =>{
            return(data)
        }
    }

    let paramsAxios= {...defaults,...options};
    console.log(paramsAxios);
    paramsAxios.values.token= accountService.getToken()  

    axios.post(
        URL,
        paramsAxios.values,
        {
            headers:{
                'Content-Type':'multipart/form-data',
            }
        }
    )
    .then(reponse =>{
        console.log(reponse)
        
        if(!reponse.data.OKIdent){
            paramsAxios.errorIdent(reponse.data);
        }
        else{
            accountService.saveToken(reponse.data.token)     // on rafraîchit le token
        }
        if(!reponse.data.OKData){
            paramsAxios.errorData(reponse.data)
        }
        else{
            paramsAxios.OKData(reponse.data.data)
        }

    })
    .catch(err => console.log(err));
}

const valideEmail = (email)=>{
    const url= 'https://api.captainverify.com/v2/verify?apikey=wHvliRN9LqhGaf7MF1T6fIyie2m74kEh&email=' + email;
    axios.get(
        url,
        {        
            header: {
            'Content-Type':'multipart/form-data',
            'Access-Control-Allow-Origin' : '*'
            }
        }        
    )
    .then( data =>{
        const existe= data.result!== 'unknown' ? true : false;
        const correction= data.did_you_mean;
        const reponse= {
            existe: existe,
            correction: correction
        }
    
    .catch(err => console.log(err));

        return reponse;
    })

}

export const axiosService={postAxios, valideEmail};