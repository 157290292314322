import React, {useState} from 'react';
import { useFormik} from 'formik';
import * as Yup from 'yup';


import { axiosService } from '@/_services/account.axios';
import FormAdh from './FormAdh';
import Header from '../../../components/header/Header';


const Adherent = () => {

    const [flagEnr,setFlagEnr] = useState(false);

    //valeurs par défaut pour le formulaire
    const [initialValues, setInitialValues] = useState( {      
        nom: '',
        prenom: '',
        dateNaissance:'',
        telephone:'',
        adresse: '',
        complements: '',
        codePostal: '',
        ville: '',
        situation:'demandee',
        dateAdhesion: '',
        montantAdhesion: '',
        modePaiement: '',
        idProfil: 9,    // adhérent
        emailValide: 0,
    })
        
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,


        validationSchema: Yup.object().shape({
            nom: Yup.string()
                .required('Nom obligatoire'),
            prenom: Yup.string()
                .required('Prénom obligatoire'),
            email: Yup.string().email('Adresse mail erronée')
                .required('Email obligatiore'),
            dateNaissance: Yup.date('Date naissance erronnée'),
            adresse: Yup.string()
                .required('Adresse obligatoire'),
            complements: Yup.string(),
            codePostal:Yup.string()
                .required('Code postal obligatoire')
                .max(5,'Code postal invalide')
                .matches('[0-9]{5}','Code postal invalide')
                ,
            ville: Yup.string()
                .required('Ville obligatoire')
        }),

        //MAJ BDD par requète API
        onSubmit: values => {
            values.domaine = 'front';    //Domaine API
            values.action = 'setNewAdherent';       // action API
            values.espace = 'public';
            values.emailValide = 1;    //conversion en num

            const options = {
                values: values,
                errorData: (data) => {
                    let span = document.getElementById("message");
                    span.firstChild && span.firstChild.remove(); //supprimer l'ancien message d'anomalie
                    let txt = document.createTextNode(data.message);
                    span.appendChild(txt);          
                },
                OKData: (data) => {
                    //action si OK
                    setFlagEnr(true);
                }
            }

            axiosService.postAxios(options);
        }
    }) 

    return (
        <>
        {
            !flagEnr &&
            <div id='adhesion'>
                <Header />
                <div className='col-6 offset-2 text-center'>
                    <legend className='md-7'>Demande d'adhésion</legend>
                    <p>Le montant de l'adhésion est de <mark>15 €</mark></p>
                    <p><mark>5 €</mark> pour les chomeurs et les étudiants</p>
                    <p>Gratuit pour les membres bienfaiteurs</p>
                </div>
                <FormAdh formik={{formik}} />
            </div>

        }
        {
            flagEnr && 
            <div >
                <Header />
                <div className='mb-2 col-10 offset-2 text-center'>
                    <legend className='md-7'>Votre demande a été enregistrée</legend>
                    <p>Nous vous remercions de souternir notre action par votre adhésion</p>
                    <p>Un message de confirmation va vous être envoyé <em>(surveillez bien vos spams)</em></p>
                    <button onClick={()=>window.location.assign(window.routes.URLFront)}>retour à l'accueil</button>
                </div>
            </div>                
        }

        </>
    );
};

export default Adherent;