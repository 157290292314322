import React, { useState, useRef} from 'react';

import { axiosService } from '@/_services/account.axios';
import Wait from '@/components/Wait';

const FormAdh = (props) => {

    const formik= props.formik.formik;
    const flagMailValide = useRef(true);
    const statutMail = props.formik.statutMail;

    const [initialValues, setInitialValues] = useState({    
        flagVerif: false,               // une vérification est en cours
        ...props.formik.formik.initialValues
    })

    const [selectProfils,setSelectProfils] = useState({...props.formik.selectProfils});
 
    //vérification de l'existance de l'adresse mail et de l'absence de double emploi
    const emailBlur= (e)=>{
        formik.handleBlur(e);

        //supprimer l'ancien message d'anomalie
        let span = document.getElementById("message");
        span.firstChild && span.firstChild.remove(); 

        if(!formik.errors.email && e.target.value){

            flagMailValide.current= false;
            setInitialValues({
                ...initialValues,
                flagVerif: true,
            });

            const options = {
                values: {
                domaine: 'front',
                action: 'verifEmail',
                espace: 'public',
                email: e.target.value,
                idAdherent: formik.initialValues.idAdherent,
                },
                OKData: () =>{
                    flagMailValide.current= true;
                    setInitialValues({
                        ...initialValues,
                        flagVerif: false,
                        emailValide: true,
                    });
                    formik.values.emailValide= true
                },
                errorData: data =>{
                    let txt = document.createTextNode(data.message);
                    span.appendChild(txt);  
                    flagMailValide.current= false; 
                    setInitialValues({
                        ...initialValues,
                        flagVerif: false,
                        emailValide: false,
                    });
                    formik.values.emailValide= false;

                }
            }

            axiosService.postAxios(options);        
        }
    }

    //formatage nom
    const nomBlur= (e)=>{
        formik.handleBlur(e);
        if(!formik.errors.nom && e.target.value){
            formik.values.nom= e.target.value.toUpperCase();
        }
    }

    //Formatage n° de téléphone nn nn nn nn nn
    const telephoneBlur= (e)=>{
        formik.handleBlur(e);
        if(!formik.errors.telephone && e.target.value){
            let telin= e.target.value
            console.log(telin)
            let tel= telin.replace(/\s+/g, '');
            console.log(tel);
            formik.values.telephone= tel.substr(0,2) + ' '+ tel.substr(2,2)+' '+tel.substr(4,2)+' '+tel.substr(6,2)+' '+tel.substr(8,2);
        }
    }


    return (
        <div>
            <form data-form-type="other" className='mb-4' onSubmit={formik.handleSubmit}>
                    <div className='row mb-2 col-10 offset-2 '>
                        <span className='text-danger ms-1 h4 strong' id='message' name='message'></span>
                    </div>
                    <div className="mb-2">
                        <label for="email" className="col-2 offset-2">Email</label>
                        <input type="email" className="col-4 " id="email" placeholder="Votre email"
                            name='email'
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={emailBlur}
                        />
                        {formik.touched.email && formik.errors.email && <span className='text-danger ms-1'>{formik.errors.email}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="nom" className="col-2 offset-2">Nom</label>
                        <input type="text" className="col-4 "  id="nom" placeholder="Votre nom"
                            name='nom'
                            onChange={formik.handleChange}
                            value={formik.values.nom}
                            onBlur={nomBlur}
                        />
                        {formik.touched.nom && formik.errors.nom && <span className='text-danger ms-1'>{formik.errors.nom}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="prenom" className="col-2 offset-2">Prenom</label>
                        <input type="prenom" className="col-4 " id="prenom" placeholder="Votre prenom"
                            name='prenom'
                            onChange={formik.handleChange}
                            value={formik.values.prenom}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.prenom && formik.errors.prenom && <span className='text-danger ms-1'>{formik.errors.prenom}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="dateNaissance" className="col-2 offset-2">Date de naissance</label>
                        <input type="date" className="col-2 " id="dateNaissance" placeholder="Votre dateNaissance"
                            name='dateNaissance'
                            onChange={formik.handleChange}
                            value={formik.values.dateNaissance}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.dateNaissance && formik.errors.dateNaissance && <span className='text-danger ms-1'>{formik.errors.dateNaissance}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="telephone" className="col-2 offset-2">Téléphone</label>
                        <input type="text" className="col-2" id="telephone" placeholder="format nnnnnnnnn sans espace"
                            name='telephone'
                            onChange={formik.handleChange}
                            value={formik.values.telephone}
                            onBlur={telephoneBlur}
                        />
                        {formik.touched.telephone && formik.errors.telephone && <span className='text-danger ms-1'>{formik.errors.telephone}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="adresse" className="col-2 offset-2">Adresse</label>
                        <input type="adresse" className="col-4 " id="adresse" placeholder="Votre adresse"
                            onChange={formik.handleChange}
                            value={formik.values.adresse}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.adresse && formik.errors.adresse && <span className='text-danger ms-1'>{formik.errors.adresse}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="complements" className="col-2 offset-2">complements</label>
                        <input type="complements" className="col-4 " id="complements" 
                            name='complements'
                            onChange={formik.handleChange}
                            value={formik.values.complements}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.complements && formik.errors.complements && <span className='text-danger ms-1'>{formik.errors.complements}</span>}
                    </div>

                    <div className="mb-2">
                        <label for="codePostal" className="col-2 offset-2">Code postal</label>
                        <input type="codePostal" className="col-2 " id="codePostal" placeholder="Code postal"aria-describedby="emailHelp"
                            name='codePostal'
                            onChange={formik.handleChange}
                            value={formik.values.codePostal}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.codePostal && formik.errors.codePostal && <span className='text-danger ms-1'>{formik.errors.codePostal}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="ville" className="col-2 offset-2">ville</label>
                        <input type="ville" className="col-4 " id="ville" placeholder="Ville"aria-describedby="emailHelp"
                            name='ville'
                            onChange={formik.handleChange}
                            value={formik.values.ville}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.ville && formik.errors.ville && <span className='text-danger ms-1'>{formik.errors.ville}</span>}
                    </div>
                    <div className='row'>
                        <button id='valider' type='submit' className=" offset-4 col-1 button btn-light mt-3" onClick={formik.handleSubmit}
                            disabled={flagMailValide.current  ? '' : 'disabled' }
                        >
                        Enregister</button>
                    </div>
            </form>
            
        </div>
    );
};

export default FormAdh;