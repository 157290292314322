import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";

import Contact from '@/pages/public/contact/Contact';
import Adhesion from '@/pages/public/adhesions/Adhesion';

function App() {
  return (
    <div>
    <BrowserRouter>
      <Routes>
          <Route index='/'  element={<Contact />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/adhesion' element={<Adhesion />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
