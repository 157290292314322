import React from 'react';

import './header.css'
import Logo from '../logo/Logo';

const Header = () => {
    return (
        <header className='header'>
            <Logo/>
        </header>
    );
};

export default Header;
